import React from 'react';

export interface titleProps {
  className?: string;
  text: string;
  fontSize?: string;
}

const Title: React.FC<titleProps> = props => {
  return (
    <h1
      className={!!props.className ? props.className : ''}
      css={{
        fontSize: !!props.fontSize ? props.fontSize : '36px',
        textAlign: 'center',
        padding: '8px 0px',
        fontWeight: 'bold',
        letterSpacing: '0.04px',
        color: '#3a2395',
        margin: '0',

        '@media (max-width: 767px)': {
          fontSize: '32px',
          letterSpacing: '0.03px',
          lineHeight: '38px',
        },

        '@media (max-width: 450px)': {
          fontSize: '20px',
          letterSpacing: '0.03px',
          lineHeight: '22px',
        },
      }}
    >
      {props.text}
    </h1>
  );
};

export default Title;
